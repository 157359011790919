
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import adminApi from "../core/services/AdminApi";
import { useI18n } from "vue-i18n";

export default defineComponent({
    name: "drive",
    components: {},
    data() {
        return {
            userDocData: "" as any,
            base64Doc: "",
            filetype: "",
            fileURL: "",
            picto: "infography",
        };
    },
    setup() {
        const { t } = useI18n();
        const i18n = useI18n();
        setCurrentPageBreadcrumbs(t("pages.dashboard.drive"), []);
        return { t, i18n };
    },
    created() {
        adminApi.getUserDocumentation().then((data: any) => {
            this.userDocData = data.categories;
        });
    },
    methods: {
        handleDocClick(id: number) {
            adminApi.getDocument(id).then((data: any) => {
                this.base64Doc = data.document.document;
                this.filetype = data.document.filetype;

                const base64result = this.base64Doc.split(",")[1];
                const blob = this.b64toBlob(base64result, this.filetype);

                this.fileURL = URL.createObjectURL(blob);

                let a = document.createElement("a");
                a.target = "_blank";
                a.href = this.fileURL;
                a.click();
            });
        },
        b64toBlob(b64Data: string, contentType = "", sliceSize = 512) {
            const byteCharacters = atob(b64Data);
            const byteArrays = [] as Array<Uint8Array>;

            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                const slice = byteCharacters.slice(offset, offset + sliceSize);

                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }

            const blob = new Blob(byteArrays, { type: contentType });
            return blob;
        },
    },
});
